import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faGoogleDrive  } from '@fortawesome/free-brands-svg-icons'
import { faChartLine, faBrain } from '@fortawesome/free-solid-svg-icons';
import { faGlobe, faMobile } from '@fortawesome/free-solid-svg-icons';


const ProjectDisplay = (props) => {
    const getIcon = () => {
        console.log(props.type);
        switch(props.type) {
            case 'Website':
                return faGlobe;
            case 'Mobile':
                return faMobile;
            case 'Machine Learning':
                return faBrain;
            case 'Data Science':
                return faChartLine;
            default:
                return null; // You can set a default icon if needed
        }
    };

    return ( 
        <div className='projectContainer'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p className='projectTitle'>{props.title}</p>
                <p className='projectType'>
                    <FontAwesomeIcon className="projectGit" icon={getIcon()} style={{ marginRight: '5px' }} /> 
                    {props.type}
                </p>
            </div>
            
            <div className='projectImageContainer'>
                <img src={props.img} className='projectImage' alt={props.title} loading='eager'/> {/* Wrap image in flexbox container */}
            </div>
            
            <br></br>
            <div className='projectButtonsContainer'>
                    <a className="projectLink" href={props.gitHub ? props.gitHub : props.colab} target="_blank" rel="noreferrer">
                        <motion.button 
                            className='projectButton'
                            whileHover={{ scale: 1.1 }}
                        >
                            <p className="projectLink">
                                <FontAwesomeIcon className="projectGit" icon={props.gitHub ? faGithub : faGoogleDrive} /> 
                                {props.gitHub ? 'GitHub' : 'Google Colab'}
                            </p>   
                        </motion.button>
                    </a>

                    {props.liveDemo &&
                     <a className="projectLink" href={props.demo} target="_blank" rel="noreferrer">
                        <motion.button 
                            className='projectButton'
                            whileHover={{ scale: 1.1 }}
                        >  
                             <p className="projectLink">Live Demo</p>   
                        </motion.button>
                     </a>
                    }
                </div>
            <p className='projectDesc'>{props.projectDesc1}</p>
        
            {/* Adding marginTop auto to push buttons and tech display to the bottom */}
            <div className='projectBottomContent'>
            
                
                <div className='techDisplay'>
                    {props.tech.sort().map((item, index) => {
                        return (
                            <p className="projectTech" key={index}>{item}</p>
                        );      
                    })}
                </div>
            </div>
        </div>
    );
};

export default ProjectDisplay;

