import React from 'react'
import headshot from './Files/Headshot.png';
import {motion} from 'framer-motion'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import AnimatedPage from './Functions/AnimatedPage';
import AnimatedTitle from './Functions/AnimatedTitle'

function Home(){
    const resumeLink = "/Justin_Sison_Resume.pdf";

    return(
        <div className = "center">
            <AnimatedPage>
        
            <div className='row'>
                <div className='col-md-6'>
                    
                    <motion.div className='headshotContainer'
                        whileHover={{
                            scale: 1.05,
                            boxShadow: "0px 0px 8px #0C243C"
                        }}
                    >
                    <img  src={headshot} className = "headshot" alt = "justin headshot" ></img>
                    </motion.div>
                        
                </div>
                <div className='col-md-6'>
                    
                    <div className='descContainer'>
                        <AnimatedTitle nameItems = {["J","u","s","t","i","n", " ", "S","i","s","o","n"]}></AnimatedTitle>
                       
                        <p className='jobTitle'>Software Developer </p>
                   
                        <div className = "homeDesc">
                            <p>Hi! I am currently employed as a Programmer Analyst at The Florida Lottery while pursuing a Master of Science in Artificial Intelligence at Florida Atlantic University.<br/><br/> I am interested in Software Development, Artificial Intelligence, Machine Learning, and Data Analytics</p>
                            
                        </div>
                    
                    </div>
                    <div className='buttonContainer'>
                        <a href={resumeLink} download="Justin_Sison_Resume.pdf">
                            <motion.button 
                                className='downloadCVButton'
                                whileHover={{
                                    scale: 1.1,

                                }}
                                >
                                <p className='downloadCV'>Download Resume <FontAwesomeIcon icon={faFileArrowDown} /></p>  
                            </motion.button>
                        </a>
               
                    </div>
                    <div className='iconContainer'>
                        <motion.button className='iconButton'
                        whileHover={{
                            scale: 1.2,
                        }}
                        > 
                            <a href="https://www.linkedin.com/in/justin-sison/" target="_blank" rel="noreferrer" >
                            <FontAwesomeIcon className='icon' icon={faLinkedin} />
                            </a>
                        </motion.button>
                        <motion.button className='iconButton'
                            whileHover={{
                                scale: 1.2,
                            }}>
                            <a href="https://github.com/jsison2021" target="_blank" rel="noreferrer" >
                            <FontAwesomeIcon className='icon' icon={faGithub} />
                            </a>
                        </motion.button>
                        <motion.button className='iconButton'
                            whileHover={{
                                scale: 1.2,
                            }}>
                            <a href="mailto: jsison64@yahoo.com" target="_blank" rel="noreferrer" >
                            <FontAwesomeIcon className='icon' icon={faEnvelope} />
                            </a>
                        </motion.button>
                    </div>
                </div>
                                    
            </div>
  
            </AnimatedPage>
        </div>
    )
   
}

export default Home;