import React, { useState } from 'react';

import AnimatedPage from './Functions/AnimatedPage';
import AnimatedTitle from './Functions/AnimatedTitle';
import ProjectDisplay from './Functions/ProjectDisplay';

import CocktailDB from './Files/CocktailDB.PNG';
import Palmacology from './Files/Palmacology.PNG';
import TailTogether from './Files/tail.png';
import StudentDB from './Files/StudentDB.PNG';
import GoogleColab from './Files/GoogleColabLogo.png';
import PhotoStorage from './Files/PhotoStorage.PNG'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faBrain } from '@fortawesome/free-solid-svg-icons';
import { faGlobe, faMobile } from '@fortawesome/free-solid-svg-icons';

function Projects() {
    const [filter, setFilter] = useState("All");

    const projects = [
        {
            type: "Website",
            title: "CockTail Recipes",
            projectDesc1: "React application integrated with Google Firebase that showcases a variety of cocktails, complete with ingredients, preparation instructions, and images using TheCocktailDB.com API. Users can effortlessly browse or search for drinks, and by logging in with their Google account, they can save and manage their favorite cocktails for quick access.",
            gitHub: 'https://github.com/jsison2021/CockTail-Recipes',
            liveDemo: true,
            demo: 'https://js-cocktail-recipes.netlify.app/',
            img: CocktailDB,
            imgClass: "projectPicture",
            tech: ["REACT","REACT-ROUTER-DOM", "GOOGLE FIREBASE AUTH", "GOOGLE FIREBASE FIRESTORE", "BOOTSTRAP", "FRAMER-MOTION","AXIOS","NODE JS","VISUAL STUDIO CODE", "NETLIFY", "API"]
        },
        {
            type: "Website",
            title: "Palmacology",
            projectDesc1: "MERN stack application integrated with Google Firebase, designed to make learning pharmacology engaging and accessible for nursing students. The platform offers a variety of interactive question formats, including single-answer, multiple-choice, and matching questions, providing a dynamic and enjoyable learning experience.",
            gitHub: 'https://github.com/jsison2021/Med-App',
            liveDemo: false,
            demo: false,
            img: Palmacology,
            imgClass: "projectPicture",
            tech: ["REACT","REACT-ROUTER-DOM", "GOOGLE FIREBASE AUTH","MONGODB", "EXPRESS","BOOTSTRAP","AXIOS","NODE JS","VISUAL STUDIO CODE"]
        },
        {
            type: "Website",
            title: "Student DB",
            projectDesc1: "ASP.NET Core application that provides full CRUD functionality for managing student records. Users can create, edit, update, and delete student information from the database.",
            gitHub: 'https://github.com/jsison2021/StudentDB.git',
            liveDemo: false,
            demo: "https://studentdbwebjs.azurewebsites.net/",
            img: StudentDB,
            imgClass: "projectPicture",
            tech: ["C#","AZURE","ASP.NET CORE MVC", "SSMS", "SQL","BOOTSTRAP","VISUAL STUDIOS","ENTITY-FRAMEWORK-CORE"]
        },
        {
            type: "Website",
            title: "Photo Storage",
            projectDesc1: "Flask application built with Python, containerized using Docker and hosted on Google Cloud Run. It allows users to upload and save photos, which invokes the Gemini AI API to generate captions that are stored alongside the images. The application utilizes Google Cloud Storage for photo storage and Google Datastore for data management, ensuring efficient handling of user-generated content.  User authentication, implemented with Google Firebase, is used to keep the photos secure, providing controlled access and management of user accounts.",
            gitHub: 'https://github.com/jsison2021/photo-storage.git',
            liveDemo: true,
            demo: "https://photo-storage-kdqbgjkv6q-uc.a.run.app/",
            img: PhotoStorage,
            imgClass: "projectPicture",
            tech: ["PYTHON","DOCKER","FLASK", "GOOGLE FIREBASE AUTH", "GOOGLE CLOUD RUN","GOOGLE CLOUD STORAGE","GOOGLE DATASTORE","GEMINI AI API","GOOGLE CLOUD SHELL EDITOR"]
        },
        {
            type: "Mobile",
            title: "Tail Together",
            projectDesc1: "iOS application that lets users seamlessly create, join, and engage in discussions around events. Users can set event details such as date, time, location, and description, while others can browse or search for events to join",
            gitHub: 'https://github.com/jsison2021/Tail-Together.git',
            liveDemo: false,
            demo: false,
            img: TailTogether,
            imgClass: "projectPicture2",
            tech: ["IOS","XCODE","SWIFT", "BACK4APP", "PARSE"]
        },
        {
            type: "Data Science",
            title: "Wine Quality Data Set",
            projectDesc1: "Data Science project that showcases three key regression techniques: Simple Linear Regression, Multiple Linear Regression, and Logistic Regression. First, Simple Linear Regression will be used to understand how a single feature, such as alcohol content, affects wine quality. Then, Multiple Linear Regression will examine the joint influence of multiple variables such as total sulfur dioxide, alcohol, pH, and density to predict the quality score. Finally, Logistic Regression will classify wines into high or low quality, transforming the problem into a binary classification task.",
            colab: 'https://colab.research.google.com/drive/17BbfshJ714aKArlip40wDizEepT0P3F4?usp=sharing',
            liveDemo: false,
            demo: false,
            img: GoogleColab,
            imgClass: "projectPicture1",
            tech: ["GOOGLE COLAB","PYTHON","PANDAS", "NUMPY", "MATLABPLOTLIB", "STATSMODELS", "SEABORN"]
        },
        {
            type: "Data Science",
            title: "Maternal Health Risk Data Set",
            projectDesc1: "Data Science project analyzes maternal health risks in rural Bangladesh using a dataset of health metrics collected from hospitals and clinics through an IoT monitoring system. It examines factors such as age, blood pressure, blood sugar, body temperature, and heart rate to assess risk levels, contributing to the UN's Sustainable Development Goals (SDGs) in health. Using K-means clustering, the data is grouped into risk profiles, evaluated for quality with silhouette and Davies-Bouldin scores. Visual tools like heatmaps and scatter matrices highlight patterns and relationships among health factors. Linear Discriminant Analysis (LDA) is then used to reduce dimensions, revealing clear separation among risk groups, particularly among older, higher-risk individuals.",
            colab: 'https://colab.research.google.com/drive/1AhzXGafi7ucRLlK7pWJP4t7Mf22qjjXD?usp=sharing',
            liveDemo: false,
            demo: false,
            img: GoogleColab,
            imgClass: "projectPicture1",
            tech: ["GOOGLE COLAB","PYTHON","PANDAS", "NUMPY", "MATLABPLOTLIB", "STATSMODELS", "SEABORN"]
        },
        {
            type: "Machine Learning",
            title: "Face Recognition on Olivetti Dataset",
            projectDesc1: "Machine Learning project focuses on building and evaluating neural networks for image classification using the Olivetti Faces dataset, which consists of images of 40 individuals. It begins with data visualization and a split into training and test sets. Several neural network architectures are explored, including single-layer models with varying node counts and deeper models with two hidden layers, all implemented using TensorFlow/Keras. To ensure reliable performance, 10-fold cross-validation is applied, and the models are assessed based on their accuracy and consistency. The project identifies the best-performing model using cross-validation metrics, demonstrating expertise in deep learning, model selection, and performance evaluation.",
            colab: 'https://colab.research.google.com/drive/1I5fHD0dNsN9_UXRU-3UZRJ3W1mB9tCo1?usp=sharing',
            liveDemo: false,
            demo: false,
            img: GoogleColab,
            imgClass: "projectPicture1",
            tech: ["GOOGLE COLAB","PYTHON","PANDAS", "NUMPY", "MATLABPLOTLIB", "SKLEARN", "TENSORFLOW"]
        },
        {
            type: "Machine Learning",
            title: "Dogs vs Cats Dataset",
            projectDesc1: "Machine Learning project focuses on building and evaluating a neural network model to classify images of dogs and cats using the Dogs vs. Cats dataset. The images are resized to 32x32 pixels and converted into numpy arrays for processing. A simple feedforward neural network is created using Keras, with layers such as normalization, dense layers, and optional dropout or batch normalization for regularization. The dataset is split into training and test sets to train the model and evaluate its performance. Additionally, 10-fold cross-validation is employed to ensure generalization, with average accuracy and standard deviation reported. The goal is to experiment with various architectures, compare performance, and analyze accuracy trends across epochs to improve the model’s classification ability.",
            colab: 'https://colab.research.google.com/drive/1aaQIMCiaFSyb8DjmYi4JGxWPyOZLC4kJ?usp=sharing',
            liveDemo: false,
            demo: false,
            img: GoogleColab,
            imgClass: "projectPicture1",
            tech: ["GOOGLE COLAB","PYTHON","PANDAS", "NUMPY", "MATLABPLOTLIB", "SKLEARN", "TENSORFLOW","KERAS"]
        }
    ];

    // Filter the projects based on the selected type
    const filteredProjects = filter === "All" 
        ? projects 
        : projects.filter((project) => project.type === filter);

    return (
        <div className='center'>
            <AnimatedPage>
                <AnimatedTitle nameItems={["P", "r", "o", "j", "e", "c", "t", "s"]} />

                {/* Filter buttons */}
                <div className="filterButtons">
                    <button class = "projectType" style = {{fontSize : 15}} onClick={() => setFilter("All")}>All</button>
                    <button class = "projectType" style = {{fontSize : 15}} onClick={() => setFilter("Data Science")}><FontAwesomeIcon className="projectGit" icon={faChartLine} /> Data Science</button>
                    <button class = "projectType" style = {{fontSize : 15}} onClick={() => setFilter("Machine Learning")}><FontAwesomeIcon className="projectGit" icon={faBrain} />Machine Learning</button>
                    <button class = "projectType" style = {{fontSize : 15}} onClick={() => setFilter("Mobile")}><FontAwesomeIcon className="projectGit" icon={faMobile} />Mobile</button>
                    <button class = "projectType" style = {{fontSize : 15}} onClick={() => setFilter("Website")}><FontAwesomeIcon className="projectGit" icon={faGlobe} />Website</button>
                </div>

                <div className='projectGrid'>
                    {filteredProjects.sort((a, b) => a.title.localeCompare(b.title)).map((item, index) => (
                        <div className="projectItem" key={index}>
                            <ProjectDisplay
                                type={item.type}
                                title={item.title}
                                projectDesc1={item.projectDesc1}
                                projectDesc2={item.projectDesc2}
                                gitHub={item.gitHub}
                                colab={item.colab}
                                liveDemo={item.liveDemo}
                                demo={item.demo}
                                img={item.img}
                                imgClass={item.imgClass}
                                tech={item.tech}
                            />
                        </div>
                    ))}
                </div>
            </AnimatedPage>
        </div>
    );
}

export default Projects;
